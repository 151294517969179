<template>
  <div>
    <form @submit.prevent="createPress" class="mt-4">
      <div class="row">
        <div class="col-6 mb-3">
          <label for="name" class="form-label"> Titre :</label>
          <textarea
            type="text"
            id="name"
            v-model="press.title"
            class="form-control"
            required
          ></textarea>
        </div>

        <div class="col-6 mb-3">
          <label for="name" class="form-label"> Titre Arabic :</label>
          <textarea
            type="text"
            id="name"
            v-model="press.titleAr"
            class="form-control"
            required
          ></textarea>
        </div>

        <div class="col-6 mb-3">
          <label for="logo" class="form-label"> Logo de la plateforme :</label>
          <input
            type="file"
            id="logo"
            @change="handleLogoUpload"
            class="form-control"
            accept="image/*"
          />
        </div>

        <div class="col-6 mb-3">
          <label for="website" class="form-label"> URL d'article : </label>
          <input
            type="text"
            id="website"
            v-model="press.url"
            class="form-control"
            required
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-3 mb-3">
          <div class="card text-start">
            <img
              :src="press.logoUrl"
              v-if="press.logo"
              alt=""
              class="img-card-top"
            />
            <div class="card-body">
              {{ press.title }}
            </div>
          </div>
        </div>
        <div class="col-3 mb-3">
          <div class="card text-end">
            <img
              :src="press.logoUrl"
              v-if="press.logo"
              alt=""
              class="img-card-top"
            />
            <div class="card-body">
              {{ press.titleAr }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 mb-3">
          <button type="submit" class="btn btn-primary">
            Enregistrer la presse
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import QuillEditorVue from "../../components/cards/QuillEditor.vue";
export default {
  compoenents: {
    QuillEditorVue,
  },
  data() {
    return {
      press: {
        title: "",
        titleAr: "",
        url: "",
        logo: null,
        logoUrl: null,
      },
    };
  },
  methods: {
    createPress() {
      const formData = new FormData();
      formData.append("title", this.press.title);
      formData.append("titleAr", this.press.titleAr);
      formData.append("url", this.press.url);
      formData.append("logo", this.press.logo);

      this.$store.dispatch("press/create", formData);
    },
    handleLogoUpload(event) {
      const file = event.target.files[0];
      this.press.logo = file;
      this.press.logoUrl = URL.createObjectURL(file); 
    },
  },
};
</script>
